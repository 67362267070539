.login-with-google-btn {
  display: flex; /* Use flexbox to align the icon and text */
  align-items: center; /* Center icon and text vertically */
  justify-content: center; /* Center content horizontally */
  transition: background-color .3s, box-shadow .3s;
  padding: 8px 16px; /* Adjust padding to match the default button */
  border: 2px solid #fff; /* Border similar to StyledButton */
  border-radius: 8px; /* Match border-radius of default buttons */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Match box-shadow of StyledButton */
  color: #fff; /* Match text color to StyledButton */
  font-size: 16px; /* Match font-size of default buttons */
  font-family: 'Karla', sans-serif; /* Match font-family to StyledButton */
  background-color: transparent; /* Match background-color of StyledButton */
  background-image: none; /* Remove the existing background image */
  width: auto; /* Ensure width is based on content */
  max-width: 300px; /* Optionally limit the max width */
  min-width: 64px; /* Optionally set a minimum width */

  /* Background and hover states */
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer; /* Change cursor on hover */

  /* Adjust icon styling */
  .login-with-google-icon {
    margin-right: 8px; /* Space between icon and text */
    color: #fff; /* Icon color to match the button text */
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.1); /* Match hover background color of StyledButton */
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15); /* Match hover box-shadow of StyledButton */
  }

  &:active {
    background-color: rgba(255, 255, 255, 0.2); /* Slightly different active background color */
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.5); /* Match focus box-shadow of StyledButton */
  }

  &:disabled {
    filter: grayscale(100%);
    background-color: #ebebeb;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    cursor: not-allowed;
  }

  & .MuiSvgIcon-root {
    color: white;
   }
}

